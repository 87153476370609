import React from "react"
import PropTypes from "prop-types"
import { SectionTitle } from "@hemalr/react-blocks"
import { primaryColor } from "./site-config"

const MainTitle = ({ text }) => {
  return (
    <SectionTitle text={text} color={primaryColor} level="h1" size="3rem" />
  )
}

MainTitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export default MainTitle
