import React from "react"
import { Link } from "gatsby"
import { Hero, SectionTitle, Button } from "@hemalr/react-blocks"
import styled from "styled-components"
import { primaryColor } from "../components/site-config"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MainTitle from "../components/mainTitle"
import Subtitle from "../components/subtitle"
import Mishal from "../components/mishal"
import HeroBg from "../images/hero-bg.jpg"
import PricingColumn from "../components/pricing-column"

const StyledH2 = styled.h2`
  color: white;
  font-weight: 300;
  font-size: 2rem;
`

const StyledSection = styled.div`
  background-color: ${props => props.color};
  padding: 2rem;
  margin: 0 auto;
`

const StyledProfileText = styled.div`
  margin: auto;
  max-width: 340px;
  text-align: center;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero height="80vh" bgImg={HeroBg} contentWidth="100%">
      <SectionTitle
        text="Accounting shouldn't be a chore"
        color="white"
        level="h1"
        size="4rem"
      />
      <StyledH2>With Everything Accounting, it isn't</StyledH2>
      <Link to="/book">
        <Button text="BOOK NOW" size="1.5rem" color={primaryColor} rounded />
      </Link>
    </Hero>
    <StyledSection color="white">
      <MainTitle text="What do we do" />
      <Subtitle text="We're on a mission to simplify and automate your accounting work." />
    </StyledSection>
    <StyledSection color="#D9F0FF">
      <MainTitle text="Who we are" />
      <Subtitle text="Your local accountant with the experience befitting 'the big 4'" />
      <PricingColumn
        icon={<Mishal />}
        price="Chief Accountant"
        name="Mishal Shah"
      >
        <StyledProfileText>
          <p>
            Mishal's favourite things to do in the whole world are smile, hug,
            and high five.
          </p>
          <p>
            Awesome was probably his first word, and he uses it to describe
            anyone and everyone – because what gets him out of bed in the
            morning are the people that make this world move, and he’s on a
            mission to help them move it.
          </p>
        </StyledProfileText>
      </PricingColumn>
    </StyledSection>
  </Layout>
)

export default IndexPage
